@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import "../node_modules/@ag-grid-community/core/dist/styles/ag-grid.css";
@import "../node_modules/@ag-grid-community/core/dist/styles/ag-theme-alpine.css";

.text-white{
    color:white;
}

.font-montserrat {
    font-family: 'Montserrat' !important;
}
.hide {
    display: none
}
.uppercase {
    text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #777777;
}
h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
  font-size: 65%;
}
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}
h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
  font-size: 75%;
}
h1,
.h1 {
  font-size: 36px;
}
h2,
.h2 {
  font-size: 30px;
}
h3,
.h3 {
  font-size: 24px;
}
h4,
.h4 {
  font-size: 18px;
}
h5,
.h5 {
  font-size: 14px;
}
h6,
.h6 {
  font-size: 12px;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.ag-cell {
    font-size: 11.5px;
    font-family: 'Montserrat' !important;
    color: #3B4752;
    font-feature-settings: "kern";
    font-weight:900;
    -webkit-font-smoothing: antialiased;
}

#audio-player wave canvas{
    background-image: url(/assets/images/audio_background.gif)!important;
}

.transcript-container .mat-dialog-container {
    padding:0px;
    margin-top: 20px;
    overflow: auto;   
}

.trends-container .mat-dialog-container {
    padding: 0px;
    margin-top: 20px;
    overflow: inherit;
}
app-transcript-window {
    height: 100%;
}
.highcharts-credits{
    display: none;
}
  
.spinner-wrapper{
    z-index: 1005;
    opacity: .9;
    background-color: gray;
    position: absolute;
    height: 200vh;
    width: 100%;
}

.spinner-box{
    padding: 170px 0;
    width:20%;
    margin:auto;
}

.mat-progress-spinner circle, .mat-spinner circle {
    stroke: #0090d9;
    }

 .admin-script-grid .ag-cell {
     font-size:16px;
 }   

 .admin-script-grid  .ag-theme-alpine .ag-popup .ag-text-area-input {
    font-size:16px;
 }

 .mat-snack-bar-container.success {
     background-color:#0090d9;
 }

 .mat-snack-bar-container.error {
    background-color: lightcoral;
}

 .mat-snack-bar-container.success {
     color:white
   
 }

 .success .mat-simple-snackbar > span {
    background-color:#0090d9;;
 }

 .error .mat-simple-snackbar > span {
    background-color:lightcoral;
 }

 .success .mat-simple-snackbar-action >button>span{
     color: yellow;
 }

 .ag-center-cols-clipper,
.ag-center-cols-container {
  min-height: 300px !important;
}

.ag-theme-alpine .ag-rich-select-list {
    height: fit-content
}


#audio-player  wave region[class="wavesurfer-region"]:not([data-id="holdtime"]) {
    height: 100px!important;
    top: 0px!important;
    z-index: 30!important;
    margin-top: -15px!important;
    min-width: 1px!important;
}

#audio-player  wave region[class="wavesurfer-region"][data-id="pausetime"] {
    opacity:.5
}
#audio-player > wave {
    overflow: visible!important;
}

.ag-header-cell-label .ag-header-cell-text {
    white-space: normal !important;
 }

 .mat-dialog-container .mat-tab-body-content {  
    overflow: unset;
}

.spinner-container-directive {
    display: inline-block;
    position: relative;
    width: 120px;
    height: 120px;
  }
  .spinner-container-directive div {
    display: inline-block;
    position: absolute;
    left: 12px;
    width: 16px;
    background: #2ba8e0;
    animation: spinner-container-directive 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .spinner-container-directive div:nth-child(1) {
    left: 12px;
    animation-delay: -0.24s;
  }
  .spinner-container-directive div:nth-child(2) {
    left: 48px;
    animation-delay: -0.12s;
  }
  .spinner-container-directive div:nth-child(3) {
    left: 84px;
    animation-delay: 0;
  }
  @keyframes spinner-container-directive {
    0% {
      top: 12px;
      height: 96px;
    }
    50%, 100% {
      top: 36px;
      height: 48px;
    }
  }